export interface RoleRaw {
  can_access_dashboard: boolean;
  can_access_upload: boolean;
  can_modify_clusters: boolean;
  can_modify_filters: boolean;
  can_open_custom_filters: boolean;
  can_qa_check: boolean;
  can_see_statistics_of_others: boolean;
  can_edit_organisation_settings: boolean;
  can_see_work_of_others: boolean;
  can_use_post_moderate_button_explore_page: boolean;
  can_validate: boolean;
  can_manage_users: boolean;
  can_manage_navee_users: boolean;
  can_create_new_organisation: boolean;
  id: number;
  name: string;
  hidden: boolean;
}

export class RoleModel {
  canAccessDashboard: boolean;

  canAccessUpload: boolean;

  canModifyClusters: boolean;

  canModifyFilters: boolean;

  canQaCheck: boolean;

  canManageUsers: boolean;

  canManageNaveeUsers: boolean;

  canSeeStatisticsOfOthers: boolean;

  canEditOrganisationSettings: boolean;

  canSeeWorkOfOthers: boolean;

  canUsePostModerateButtonExplorePage: boolean;

  canValidate: boolean;

  canCreateNewOrganisation: boolean;

  id: number;

  name: string;

  hidden: boolean;

  constructor(
    prop: Pick<
      RoleModel,
      | 'canAccessDashboard'
      | 'canAccessUpload'
      | 'canModifyClusters'
      | 'canModifyFilters'
      | 'canQaCheck'
      | 'canSeeStatisticsOfOthers'
      | 'canSeeWorkOfOthers'
      | 'canUsePostModerateButtonExplorePage'
      | 'canEditOrganisationSettings'
      | 'canValidate'
      | 'canManageUsers'
      | 'canManageNaveeUsers'
      | 'canCreateNewOrganisation'
      | 'id'
      | 'name'
      | 'hidden'
    >,
  ) {
    this.canAccessDashboard = prop.canAccessDashboard;
    this.canAccessUpload = prop.canAccessUpload;
    this.canModifyClusters = prop.canModifyClusters;
    this.canModifyFilters = prop.canModifyFilters;
    this.canQaCheck = prop.canQaCheck;
    this.canSeeStatisticsOfOthers = prop.canSeeStatisticsOfOthers;
    this.canSeeWorkOfOthers = prop.canSeeWorkOfOthers;
    this.canEditOrganisationSettings = prop.canEditOrganisationSettings;
    this.canUsePostModerateButtonExplorePage =
      prop.canUsePostModerateButtonExplorePage;
    this.canValidate = prop.canValidate;
    this.canManageUsers = prop.canManageUsers;
    this.canManageNaveeUsers = prop.canManageNaveeUsers;
    this.canCreateNewOrganisation = prop.canCreateNewOrganisation;
    this.id = prop.id;
    this.name = prop.name;
    this.hidden = prop.hidden;
  }

  get isNaveeAdmin() {
    return (this.name ?? '').toLowerCase().includes('navee admin');
  }

  get canSeeOrgSettings() {
    return this.canEditOrganisationSettings;
  }

  static createFromRoleRaw(prop: RoleRaw) {
    return new RoleModel({
      canAccessDashboard: prop.can_access_dashboard,
      canAccessUpload: prop.can_access_upload,
      canModifyClusters: prop.can_modify_clusters,
      canModifyFilters: prop.can_modify_filters,
      canEditOrganisationSettings: prop.can_edit_organisation_settings,
      canQaCheck: prop.can_qa_check,
      canSeeStatisticsOfOthers: prop.can_see_statistics_of_others,
      canSeeWorkOfOthers: prop.can_see_work_of_others,
      canUsePostModerateButtonExplorePage:
        prop.can_use_post_moderate_button_explore_page,
      canValidate: prop.can_validate,
      canManageUsers: prop.can_manage_users,
      canManageNaveeUsers: prop.can_manage_navee_users,
      canCreateNewOrganisation: prop.can_create_new_organisation,
      id: prop.id,
      name: prop.name,
      hidden: prop.hidden,
    });
  }
}
