import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
  GetMainInfoAndMe,
  GetMainInfoAndMeType,
} from 'types/sharedWorker/messages/GetMainInfoAndMe';
import { GlobalData } from 'product-types/src/domain/Domain';
import {
  GetCurrentOrganisation,
  GetCurrentOrganisationType,
} from '../../../types/sharedWorker/messages/GetCurrentOrganisation';
import { LogoutMessageType } from '../../../types/sharedWorker/messages/LogoutMessage';
import { updateLinkHandled } from '../../LoginPage/actions';
import { LinkHandlingState } from '../../LoginPage/reducer';
import {
  SwitchOrganisationMessage,
  SwitchOrganisationMessageType,
} from '../../../types/sharedWorker/messages/SwitchOrganisationMessage';
import { Message } from '../../../types/sharedWorker/messages/Message';
import { AppState } from '../../../store/storeAccess';
import { sharedWorkerClient } from '../../../workers/shared';

interface SharedWorkerHookProps {
  location: { state: { redirectTo: string; message: string } };
}
export default function useSharedWorker({ location }: SharedWorkerHookProps) {
  const loginPage = useSelector((state: AppState) => state.loginPage);
  const optionsLoadedData = useSelector(
    (state: AppState) => state.filters_bar?.optionsLoadedData,
  );
  const filters = useSelector((state: AppState) => state.filters_bar?.filters);
  const exportTemplates = useSelector(
    (state: AppState) => state.navbar?.exportTemplates,
  );

  const dispatch = useDispatch();

  const onMessage = useCallback(
    (message: Message) => {
      const targetOrganisationId = (message as SwitchOrganisationMessage)
        .payload?.data?.organisationId;
      switch (message.type) {
        case SwitchOrganisationMessageType: {
          if (loginPage.currentUser?.data?.organisation?.uid) {
            if (
              loginPage.currentUser?.data?.organisation?.uid !==
              targetOrganisationId
            ) {
              if (targetOrganisationId !== loginPage.targetOrganisation?.uid) {
                dispatch(
                  push({
                    pathname: '/confirm-select-organisation',
                    state: {
                      organisationId:
                        loginPage.currentUser?.data?.organisation?.uid,
                      redirectTo:
                        window.location.pathname + window.location.search,
                    },
                  }),
                );
              }
            }
          } else if (location.state?.redirectTo) {
            dispatch(updateLinkHandled(LinkHandlingState.unknown));
            dispatch(push(location.state?.redirectTo));
          } else if (!loginPage.isAuthenticated) {
            window.location.reload();
          }
          break;
        }

        case GetCurrentOrganisationType: {
          if (
            (message as GetCurrentOrganisation).payload?.data?.type ===
            'questionWorker'
          ) {
            if (loginPage.targetOrganisation?.uid) {
              sharedWorkerClient.sendMessage(
                new GetCurrentOrganisation({
                  payload: {
                    data: {
                      type: 'answerTab',
                      organisationId: loginPage.targetOrganisation?.uid,
                    },
                  },
                }),
              );
            } else {
              sharedWorkerClient.sendMessage(
                new GetCurrentOrganisation({
                  payload: {
                    data: {
                      type: 'answerTab',
                      organisationId:
                        loginPage.currentUser?.data?.organisation?.uid ?? null,
                    },
                  },
                }),
              );
            }
          }
          break;
        }

        case GetMainInfoAndMeType: {
          if (
            message.payload?.data?.type === 'request' &&
            loginPage.currentUser.data?.organisation?.uid ===
              message.payload?.data?.orgUid &&
            loginPage.currentUser.data !== null
          ) {
            if (
              optionsLoadedData?.availableCategories?.data !== null &&
              optionsLoadedData?.geographySelectOptions?.data !== null &&
              optionsLoadedData?.websites?.data !== null &&
              optionsLoadedData?.availableLabels?.data !== null &&
              optionsLoadedData?.availableImageLabels?.data !== null &&
              optionsLoadedData?.accountLabels?.data !== null &&
              optionsLoadedData?.featureLabels?.data !== null &&
              optionsLoadedData?.users?.data !== null &&
              filters !== null &&
              optionsLoadedData?.availableWebsiteCategories?.data !== null &&
              optionsLoadedData?.tags?.account?.data !== null &&
              optionsLoadedData?.tags?.duplicated_group?.data !== null &&
              optionsLoadedData?.tags?.post?.data !== null &&
              optionsLoadedData?.insightOptions?.data !== null &&
              optionsLoadedData?.tags?.upload_history?.data !== null &&
              optionsLoadedData?.tags?.cluster?.data !== null &&
              exportTemplates?.post?.data !== null
            ) {
              sharedWorkerClient.sendMessage(
                new GetMainInfoAndMe({
                  payload: {
                    data: {
                      type: 'response',
                      me: loginPage.currentUser.data,
                      mainInfo: new GlobalData({
                        insightOptions: optionsLoadedData.insightOptions.data,
                        categories:
                          optionsLoadedData.availableCategories.data.toRawModel(),
                        geographyZones:
                          optionsLoadedData.geographySelectOptions.data,
                        topWebsites: optionsLoadedData.websites.data,
                        labels: {
                          post: optionsLoadedData.availableLabels.data,
                          image: optionsLoadedData.availableImageLabels.data,
                          account: optionsLoadedData.accountLabels.data,
                        },
                        moderationReasons: optionsLoadedData.featureLabels.data,
                        users: optionsLoadedData.users.data,
                        filters,
                        websiteCategories:
                          optionsLoadedData.availableWebsiteCategories.data,
                        tags: {
                          account: optionsLoadedData.tags.account.data,
                          duplicated_group:
                            optionsLoadedData.tags.duplicated_group.data,
                          post: optionsLoadedData.tags.post.data,
                          upload_history:
                            optionsLoadedData.tags.upload_history.data,
                          cluster: optionsLoadedData.tags.cluster.data,
                        },
                        exportTemplates: {
                          post: exportTemplates.post.data,
                          image: exportTemplates.image.data ?? [],
                          website: exportTemplates.website.data ?? [],
                          account: exportTemplates.account.data ?? [],
                          cluster: exportTemplates.cluster.data ?? [],
                        },
                      }),
                    },
                  },
                }),
              );
            }
          }
          break;
        }

        case LogoutMessageType: {
          dispatch(
            push({
              pathname: '/login',
              state: {
                message: (message as any).payload?.data,
              },
            }),
          );
          break;
        }
        default:
          console.error('default');
      }
    },
    [
      loginPage.currentUser,
      loginPage.currentUser.data,
      loginPage.currentUser.state,
      optionsLoadedData,
      exportTemplates,
    ],
  );

  useEffect(() => {
    sharedWorkerClient.addMessageListener(onMessage);
    return () => {
      sharedWorkerClient.removeMessageListener(onMessage);
    };
  }, [onMessage]);
}
