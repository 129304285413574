import { UploadHistoryFiltersModel } from 'types/filters/MoleculesFilterImplementation/UploadHistoryFiltersModel';
import { DashboardFiltersModel } from 'types/filters/MoleculesFilterImplementation/DashboardFilterModel';
import { VendorFilterModel } from 'types/filters/MoleculesFilterImplementation/VendorFilterModel';
import { FeedFilterModel } from 'types/filters/MoleculesFilterImplementation/FeedFilterModel';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { ModerationReason } from 'product-types/src/domain/moderationReason/ModerationReason';
import { UserLightModel } from 'product-types/src/domain/user/UserLightModel';
import { WebsiteCategory } from 'product-types/src/domain/website/WebsiteCategory';
import { TagModel, TagTypeEnum } from 'product-types/src/domain/tag/Tag';
import { GeographySelectOptions } from 'product-types/src/domain/geo/Geo';
import { WebsiteSuggestion } from 'product-types/src/domain/website/Website';
import { Label } from 'product-types/src/domain/label/Label';
import { ProductCategoryTree } from 'types/src/domain/productCategory';
import { Insight } from 'product-types/src/domain/insight/insight';
import { RoleModel } from 'types/src/domain/role/Role';
import { ContactMainInfo } from 'product-types/src/domain/contact/Contact';
import { Currency } from 'types/src/domain/Currency';
import { OrganisationStatus } from 'product-types/src/domain/organisation/OrganisationStatus';
import { LanguageModel } from 'product-types/src/domain/language/language';
import { CrawlingDomain } from 'types/src/domain/crawlingDomain';
import { ScrappingSource } from 'product-types/src/domain/scrappingSource/ScrappingSource';

interface TagOptionData {
  [TagTypeEnum.account]: FetchableData<Array<TagModel>>;
  [TagTypeEnum.duplicatedGroup]: FetchableData<Array<TagModel>>;
  [TagTypeEnum.post]: FetchableData<Array<TagModel>>;
  [TagTypeEnum.uploadHistory]: FetchableData<Array<TagModel>>;
  [TagTypeEnum.vendor]: FetchableData<Array<TagModel>>;
}

export interface LoadedDataState {
  availableCategories: FetchableData<ProductCategoryTree>;
  availableWebsiteCategories: FetchableData<Array<WebsiteCategory>>;
  availableContactTypes: FetchableData<Array<ContactMainInfo>>;
  geographySelectOptions: FetchableData<GeographySelectOptions>;
  availableLabels: FetchableData<Array<Label>>;
  availableImageLabels: FetchableData<Array<Label>>;
  accountLabels: FetchableData<Array<Label>>;
  organisationStatuses: FetchableData<Array<OrganisationStatus>>;
  languages: FetchableData<Array<LanguageModel>>;
  scrappingSource: FetchableData<Array<ScrappingSource>>;
  insightOptions: FetchableData<Array<Insight>>;
  postsData: any;
  websites: FetchableData<Array<WebsiteSuggestion>>;
  tags: TagOptionData;
  filters: any;
  featureLabels: FetchableData<Array<ModerationReason>>;
  users: FetchableData<Array<UserLightModel>>;
  roles: FetchableData<Array<RoleModel>>;
  currencies: FetchableData<Array<Currency>>;
  crawlingDomains: FetchableData<Array<CrawlingDomain>>;
}

// refactor loaded data initial state
const loadedDataInitialState = {
  availableCategories: new FetchableData<ProductCategoryTree>(
    FetchableData.default,
  ),
  availableContactTypes: new FetchableData<Array<ContactMainInfo>>(
    FetchableData.default,
  ),
  availableWebsiteCategories: new FetchableData<Array<WebsiteCategory>>(
    FetchableData.default,
  ),
  geographySelectOptions: new FetchableData<GeographySelectOptions>(
    FetchableData.default,
  ),
  organisationStatuses: new FetchableData<Array<OrganisationStatus>>(
    FetchableData.default,
  ),
  currencies: new FetchableData<Array<Currency>>(FetchableData.default),
  insightOptions: new FetchableData<Array<Insight>>(FetchableData.default),
  availableLabels: new FetchableData<Array<Label>>(FetchableData.default),
  availableImageLabels: new FetchableData<Array<Label>>(FetchableData.default),
  accountLabels: new FetchableData<Array<Label>>(FetchableData.default),
  languages: new FetchableData<Array<LanguageModel>>(FetchableData.default),
  crawlingDomains: new FetchableData<Array<CrawlingDomain>>(
    FetchableData.default,
  ),
  scrappingSource: new FetchableData<Array<ScrappingSource>>(
    FetchableData.default,
  ),
  postsData: {},
  websites: new FetchableData<Array<WebsiteSuggestion>>(FetchableData.default),
  tags: {
    [TagTypeEnum.account]: new FetchableData<Array<TagModel>>(
      FetchableData.default,
    ),
    [TagTypeEnum.duplicatedGroup]: new FetchableData<Array<TagModel>>(
      FetchableData.default,
    ),
    [TagTypeEnum.post]: new FetchableData<Array<TagModel>>(
      FetchableData.default,
    ),
    [TagTypeEnum.uploadHistory]: new FetchableData<Array<TagModel>>(
      FetchableData.default,
    ),
    [TagTypeEnum.vendor]: new FetchableData<Array<TagModel>>(
      FetchableData.default,
    ),
  },
  filters: {},
  featureLabels: new FetchableData<Array<ModerationReason>>(
    FetchableData.default,
  ),
  users: new FetchableData<Array<UserLightModel>>(FetchableData.default),
};

export interface SearchBarState {
  post_url: Array<any>;
  not_found_images: Array<any>;
  searching_images: Array<any>;
  found_images: Array<any>;
  duplicated_group_id: Array<any>;
  post_id: Array<any>;
}

export const createDashboardFiltersInitialState = (props?: any) =>
  new DashboardFiltersModel(props);

export const createVendorsFiltersInitialState = (props?: any) =>
  new VendorFilterModel(props);

export const createFeedFiltersInitialState = (props?: any) =>
  new FeedFilterModel(props);

export const createLoadedDataInitialState = (): LoadedDataState => ({
  ...loadedDataInitialState,
});

export const createUploadHistoryFiltersInitialState = (props?: any) =>
  new UploadHistoryFiltersModel(props);
