export interface LanguageRaw {
  value: string;
  label: string;
  is_priority: boolean;
}

export class LanguageModel {
  value: string;

  label: string;

  isPriority: boolean;

  constructor(prop: Pick<LanguageModel, 'isPriority' | 'label' | 'value'>) {
    this.value = prop.value;
    this.label = prop.label;
    this.isPriority = prop.isPriority;
  }

  static fromRaw(raw: LanguageRaw): LanguageModel {
    return new LanguageModel({
      value: raw.value,
      label: raw.label,
      isPriority: raw.is_priority,
    });
  }
}
