import React, { useCallback, useContext, useState } from 'react';
import { WebsiteCategory } from 'product-types/src/domain/website/WebsiteCategory';
import { Tabs } from 'ui/src/components/atoms/Tabs/Tabs';
import { Flex, Tooltip } from 'antd';
import { WebsiteSuggestion } from 'product-types/src/domain/website/Website';
import { Filter } from '../../../types/filters/AtomicFilters/Filter';
import {
  FilterProviderContext,
  NewFilterProviderContext,
} from '../../../providers/NewFilterProvider/NewFilterProvider';
import { WebsiteFilter } from '../../../types/filters/AtomicFiltersImplementation/Website/WebsiteFilter';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';
import './styles.css';
import { WebsiteTab } from './WebsiteTab';
import { WebsiteCategoryTab } from './WebsiteCategoryTab';
import {
  ctrlKey,
  enterKey,
  useKeyboardInteraction,
} from '../../../hooks/keyboardActions/useKeyboardInteraction';

export interface NewWebsiteFilterProps {
  value: WebsiteFilter;
  onChange: (v: Filter) => void;
}

const include = 'include';
const exclude = 'exclude';

export const NewWebsiteFilter = (props: NewWebsiteFilterProps) => {
  const context = useContext<NewFilterProviderContext>(FilterProviderContext);
  const [forceClose, updateForceClose] = useState({});

  const applyFilters = useCallback(() => {
    updateForceClose(() => ({}));
    context.applyFilters?.();
  }, [context.applyFilters, forceClose]);

  useKeyboardInteraction({
    observable: [context.applyFilters],
    subscriptions: [
      {
        conditions: [ctrlKey, enterKey],
        callback: () => {
          if (context.applyFilters) {
            updateForceClose(() => ({}));
          }
        },
      },
    ],
  });

  const onSelectCategory = useCallback(
    (operation, categories: Array<WebsiteCategory>) => {
      if (operation === include) {
        props.onChange(
          new WebsiteFilter({
            ...props.value,
            value: props.value.value.addIncludedCategory(categories),
          }),
        );
      } else if (operation === exclude) {
        props.onChange(
          new WebsiteFilter({
            ...props.value,
            value: props.value.value.addExcludedCategory(categories),
          }),
        );
      }
    },
    [props.value.value, props.onChange],
  );

  const onSelectWebsite = useCallback(
    (
      operation: typeof include | typeof exclude,
      website: Array<WebsiteSuggestion>,
    ) => {
      if (operation === include) {
        props.onChange(
          new WebsiteFilter({
            ...props.value,
            value: props.value.value.addIncludedWebsite(website),
          }),
        );
      } else if (operation === exclude) {
        props.onChange(
          new WebsiteFilter({
            ...props.value,
            value: props.value.value.addExcludedWebsite(website),
          }),
        );
      }
    },
    [props.value.value, props.onChange],
  );

  const onClearWebsite = useCallback(
    (operation: typeof include | typeof exclude) => {
      if (operation === include) {
        props.onChange(
          new WebsiteFilter({
            ...props.value,
            value: props.value.value.setIncludedWebsite([]),
          }),
        );
      } else if (operation === exclude) {
        props.onChange(
          new WebsiteFilter({
            ...props.value,
            value: props.value.value.setExcludedWebsite([]),
          }),
        );
      }
    },
    [props.value.value, props.onChange],
  );

  const onClearCategories = useCallback(
    (operation: typeof include | typeof exclude) => {
      if (operation === include) {
        props.onChange(
          new WebsiteFilter({
            ...props.value,
            value: props.value.value.setIncludedCategory([]),
          }),
        );
      } else if (operation === exclude) {
        props.onChange(
          new WebsiteFilter({
            ...props.value,
            value: props.value.value.setExcludedCategory([]),
          }),
        );
      }
    },
    [props.value.value, props.onChange],
  );

  const onRemoveCategory = useCallback(
    (operation: typeof include | typeof exclude, website: WebsiteCategory) => {
      if (operation === include) {
        props.onChange(
          new WebsiteFilter({
            ...props.value,
            value: props.value.value.removeIncludedCategory(website),
          }),
        );
      } else if (operation === exclude) {
        props.onChange(
          new WebsiteFilter({
            ...props.value,
            value: props.value.value.removeExcludedCategory(website),
          }),
        );
      }
    },
    [props.value.value, props.onChange],
  );

  const onRemoveWebsite = useCallback(
    (
      operation: typeof include | typeof exclude,
      website: WebsiteSuggestion,
    ) => {
      if (operation === include) {
        props.onChange(
          new WebsiteFilter({
            ...props.value,
            value: props.value.value.removeIncludedWebsite(website),
          }),
        );
      } else if (operation === exclude) {
        props.onChange(
          new WebsiteFilter({
            ...props.value,
            value: props.value.value.removeExcludedWebsite(website),
          }),
        );
      }
    },
    [props.value.value, props.onChange],
  );

  const maxTagCount = 25;
  const maxTagPlaceholder = (omittedValues) => (
    <Tooltip
      overlayStyle={{ pointerEvents: 'none' }}
      title={omittedValues.map(({ label }) => label).join(', ')}
    >
      <span>+{omittedValues.length}</span>
    </Tooltip>
  );

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        gap="1rem"
        style={{
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
          width: 396,
          minHeight: 60,
        }}
      >
        <Tabs
          defaultActiveKey="web"
          centered
          destroyInactiveTabPane
          animated={false}
          items={[
            {
              key: 'web',
              label: <div>WEBSITE</div>,
              children: (
                <WebsiteTab
                  maxTagCount={maxTagCount}
                  maxTagPlaceholder={maxTagPlaceholder}
                  onSelectWebsite={onSelectWebsite}
                  onClearWebsite={onClearWebsite}
                  websiteInclude={props.value.value.websiteInclude}
                  websiteExclude={props.value.value.websiteExclude}
                  applyFilters={applyFilters}
                  onRemoveWebsite={onRemoveWebsite}
                />
              ),
            },
            {
              key: 'cat',
              label: <div>CATEGORY</div>,
              children: (
                <WebsiteCategoryTab
                  categories={context.availableWebsiteCategories ?? []}
                  websiteCategoryExclude={
                    props.value.value.websiteCategoryExclude
                  }
                  websiteCategoryInclude={
                    props.value.value.websiteCategoryInclude
                  }
                  onClearCategories={onClearCategories}
                  onSelectCategory={onSelectCategory}
                  applyFilters={applyFilters}
                  onRemoveCategory={onRemoveCategory}
                  showApplyButton={context.showApplyButton}
                  maxTagCount={maxTagCount}
                  maxTagPlaceholder={maxTagPlaceholder}
                />
              ),
            },
          ]}
        />
      </Flex>
    ),
    [
      onSelectCategory,
      onSelectWebsite,
      onClearWebsite,
      onRemoveWebsite,
      onClearCategories,
      context.showApplyButton,
      context.websites,
      context.availableWebsiteCategories,
      props.value.value.websiteCategoryExclude,
      props.value.value.websiteCategoryInclude,
      props.value.value.websiteExclude,
      props.value.value.websiteInclude,
    ],
  );

  if (!context.availableWebsiteCategories || !context.websites) {
    return null;
  }

  return (
    <FilterWithMenuWrapper
      className="website-category-filter"
      text="Website"
      forceClose={forceClose}
      count={props.value.displayingFilterValue.length}
      renderer={renderer}
    />
  );
};
